<template>
	<div>
		<page-bar :tabs="tabs" name="Appliances"></page-bar>

		<v-slide-x-transition mode="out-in">
			<router-view></router-view>
		</v-slide-x-transition>
	</div>
</template>

<script>
import PageBar from '@/components/global/PageBar'

export default {
	name: 'AppliancesIndex',
	components: {PageBar},
	data: () => ({
		tabs: [
			{title: 'Groups', link: 'brand-groups'},
			{title: 'Brands', link: 'brands'},
		]
	}),
}
</script>

<style scoped>

</style>
